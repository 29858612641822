import {ACTIVATION_STATUS} from '@jetCommon/constants/company-constants.js';
import {PAYROLL_DETAIL_SECTION} from '@/helpers/payroll-detail-section-constants.js';
import {afterEachHandler, beforeEachHandler, commonRoutes, onError} from '@jetCommon/router.js';
import {api} from '@/api';
import {createRouter, createWebHashHistory} from 'vue-router';
import {scrollBehavior} from '@jetCommon/router.js';

const routes = [
    ...commonRoutes,
    {
        path: '/',
        redirect: '/aziende',
        component: () => import('@/layouts/SidebarLayout.vue'),
        children: [
            {
                path: 'assunzioni',
                children: [
                    {
                        path: ':tab(tab-in-corso|tab-in-carico|tab-completate)',
                        component: () => import('@/features/hiring/views/HiringsView.vue'),
                        name: 'hiringsViewWithTab',
                    },
                    {
                        path: '',
                        redirect: to => ({path: `${to.path}/tab-in-carico`, replace: true}),
                        name: 'hiringsView',
                    },
                ],
            },
            {
                path: 'studi',
                component: () => import('@/features/studio/views/JetStudioView.vue'),
                name: 'jetStudioView',
            },
            {
                path: 'studi/:studioId',
                component: () => import('@/features/studio/views/JetStudioDetailView.vue'),
                name: 'jetStudioDetailView',
            },
            {
                path: 'accessi-inps',
                component: () => import('@/features/inps/views/InpsAccessView.vue'),
                name: 'inpsAccessView',
            },
            {
                path: 'aziende',
                props: {activationStatus: ''},
                component: () => import('@/views/CompaniesView.vue'),
                name: 'allCompaniesView',
            },
            {
                path: 'aziende/da-assegnare',
                props: {activationStatus: ACTIVATION_STATUS.NOT_ASSIGNED},
                component: () => import('@/views/CompaniesView.vue'),
                name: 'notAssignedCompaniesView',
            },
            {
                path: 'aziende/onboarding',
                props: {activationStatus: ACTIVATION_STATUS.ONBOARDING},
                component: () => import('@/views/CompaniesView.vue'),
                name: 'onboardingCompaniesView',
            },
            {
                path: 'aziende/attive',
                props: {activationStatus: ACTIVATION_STATUS.ACTIVE},
                component: () => import('@/views/CompaniesView.vue'),
                name: 'activeCompaniesView',
            },
            {
                path: 'aziende/disattivate',
                props: {activationStatus: ACTIVATION_STATUS.DEACTIVATED},
                component: () => import('@/views/CompaniesView.vue'),
                name: 'deactivatedCompaniesView',
            },
            {
                path: 'aziende/:companyId',
                component: () => import('@/views/CompanyDetailView.vue'),
                name: 'companyDetailView',
            },
            {
                path: 'aziende/:companyId/onboarding',
                component: () => import('@/features/onboarding/views/CompanyOnboardingView.vue'),
                name: 'companyOnboarding',
            },
            {
                path: 'aziende/:companyId/contratto',
                component: () => import('@/views/CompanyContractView.vue'),
                name: 'companyContract',
            },
            {
                path: 'aziende/:companyId/note',
                component: () => import('@/features/notes/views/CompanyNotesView.vue'),
                name: 'companyNotes',
            },
            {
                path: 'aziende/:companyId/personale/:tab(tab-da-sincronizzare|tab-in-forza|tab-in-arrivo|tab-contratto-terminato)?',
                component: () => import('@/views/CompanyEmployeesView.vue'),
                name: 'companyEmployees',
            },
            {
                path: 'aziende/:companyId/professionisti-esterni',
                component: () => import('@/features/contractors/views/CompanyContractorsView.vue'),
                name: 'companyContractors',
            },
            {
                path: 'aziende/:companyId/admin',
                component: () => import('@/views/CompanyAdminsView.vue'),
                name: 'companyAdmins',
            },
            {
                path: 'aziende/:companyId/compliance',
                component: () => import('@/views/CompanyComplianceView.vue'),
                name: 'companyCompliance',
            },
            {
                path: 'aziende/:companyId/commercialisti',
                component: () => import('@/views/CompanyAccountantsView.vue'),
                name: 'companyAccountants',
            },
            {
                path: 'aziende/:companyId/impostazioni',
                component: () => import('@/views/CompanySettingsView.vue'),
                name: 'companySettings',
            },
            {
                path: 'aziende/:companyId/documenti',
                component: () => import('@/views/CompanyDocumentsView.vue'),
                name: 'companyDocuments',
            },
            {
                path: 'aziende/:companyId/pagamenti',
                component: () => import('@/views/CompanyPaymentsView.vue'),
                name: 'companyPayments',
            },
            {
                path: 'aziende/:companyId/extra',
                component: () => import('@/views/CompanyExtraInformationView.vue'),
                name: 'companyExtraInformation',
            },
            {
                path: 'cicli-paghe/in-corso/:payrollPeriod',
                redirect: to => ({path: `${to.path}/tab-da-elaborare`, replace: true}),
                name: 'pendingPayrollsView',
            },
            {
                // eslint-disable-next-line max-len, vue/max-len
                path: 'cicli-paghe/in-corso/:payrollPeriod/:tab(tab-da-lanciare|tab-da-elaborare|tab-elaborati|tab-invio-dichiarativi)',
                component: () => import('@/views/PendingPayrollsView.vue'),
                name: 'pendingPayrollsViewWithTab',
            },
            {
                path: 'cicli-paghe/dettaglio/:payrollPeriod/:companyId(\\d+)',
                children: [
                    {
                        path: '',
                        component: () => import('@/views/PayrollDetailView.vue'),
                        name: 'payrollDetailView',
                        props: {section: PAYROLL_DETAIL_SECTION.SITUATION},
                    },
                    {
                        path: 'export-jet-to-job',
                        component: () => import('@/views/PayrollDetailView.vue'),
                        name: 'payrollDetailExportJetToJob',
                        props: {section: PAYROLL_DETAIL_SECTION.EXPORT_JET_2_JOB},
                    },
                    {
                        path: 'controllo-elaborazione',
                        component: () => import('@/views/PayrollDetailView.vue'),
                        name: 'payrollDetailPayslipsCompare',
                        props: {section: PAYROLL_DETAIL_SECTION.PAYSLIPS_COMPARE},
                    },
                    {
                        path: 'import-job-to-jet',
                        redirect: to => ({path: `${to.path}/tab-cedolini`, replace: true}),
                        name: 'payrollDetailImportJobToJet',
                        props: {section: PAYROLL_DETAIL_SECTION.IMPORT_JOB_2_JET},
                    },
                    {
                        path: 'import-job-to-jet/:tab(tab-cedolini|tab-documenti)',
                        component: () => import('@/views/PayrollDetailView.vue'),
                        name: 'payrollDetailImportJobToJetWithTab',
                        props: {section: PAYROLL_DETAIL_SECTION.IMPORT_JOB_2_JET},
                    },
                    {
                        path: 'declarations',
                        component: () => import('@/views/PayrollDetailView.vue'),
                        name: 'payrollDetailDeclarations',
                        props: {section: PAYROLL_DETAIL_SECTION.DECLARATIONS},
                    },
                ],
            },
            {
                path: 'cicli-paghe/conclusi',
                component: () => import('@/views/ClosedPayrollsView.vue'),
                name: 'closedPayrollsView',
            },
            {
                path: 'fondi-pensione-complementare',
                component: () => import('@/features/welfare-funds/views/WelfareFundsView.vue'),
                name: 'welfareFundsView',
            },
            {
                path: 'fondi-pensione-complementare/:welfareFundId',
                component: () => import('@/features/welfare-funds/views/WelfareFundDetailView.vue'),
                name: 'welfareFundDetailView',
            },
            {
                path: 'gruppi-aziendali',
                component: () => import('@/views/CompanyGroupsView.vue'),
                name: 'companyGroupsView',
            },
            {
                path: 'impostazioni',
                component: () => import('@/views/SettingsView.vue'),
                name: 'settingsView',
            },
            {
                path: 'impostazioni-applicazione/video-tutorial',
                component: () => import('@/views/VideoTutorialSettingsView.vue'),
                name: 'videoTutorialSettingsView',
            },
            {
                path: 'strumenti/comunicazioni_unilav',
                component: () => import('@/views/UnilavValidationView.vue'),
                name: 'unilavNotificationView',
            },
            {
                path: 'strumenti/comunicazioni_enti',
                component: () => import('@/features/inps/views/inpsNotificationView.vue'),
                name: 'inpsNotificationView',
            },
            {
                path: 'strumenti/export-dati',
                component: () => import('@/views/ExportDataView.vue'),
                name: 'exportDataView',
            },
            {
                path: 'versamenti-azienda-fondo-pensione-complementare/:welfareFundCompanyPaymentId',
                redirect: to => ({path: `${to.path}/informazioni`, replace: true}),
                name: 'welfareFundCompanyPaymentDetailView',
            },
            {
                path: 'versamenti-azienda-fondo-pensione-complementare/:welfareFundCompanyPaymentId/:tab(informazioni|tabella-dettagli-dipendente)',
                component: () => import('@/features/welfare-funds/views/WelfareFundCompanyPaymentDetailView.vue'),
                name: 'welfareFundCompanyPaymentDetailViewWithTab',
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: () => '/404',
    },
];

const router = createRouter({
    scrollBehavior,
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(async (to, from) => await beforeEachHandler(to, from, api));

router.afterEach(afterEachHandler);

router.onError(onError);

export default router;
